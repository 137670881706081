import React from 'react'
import formi from '../Images/editp.png'

const Ticket = () => {
  return (
    <>
       <section className='sec02_ep'>
        <div className='container'>
        <h2 className='sec_heading_h2 w josefin text-center'> My Personal Information</h2>
            <div className='row'>
                <div className='col-lg-6 col-md-6 text-center'>
                    <img src={formi} className='form_img' />
                </div>
                <div className='col-lg-6 col-md-6'>
                    <div className='sec2_box'>
                        <form className='edit_pro'>
                            <label className='form_label josefin w'>Select Query </label>
                            <select className='form_label josefin w' id="cars" name="carlist" form="carform">
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="opel">Opel</option>
                                <option value="audi">Audi</option>
                            </select>
                            <label className='form_label josefin w'>ID Number </label>
                            <input className='form_input inter w' type='tel' placeholder='' />
                            <label className='form_label josefin w'>Hash ID </label>
                            <input className='form_input inter w' type='text' placeholder='' />
                            <label className='form_label josefin w'>Screen Shot </label>
                            <input className='form_input inter w' type='file' placeholder='' />
                            <input className='btn_m form_btn josefin w' type='submit' value='Update' />
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </section>
    </>
  )
}

export default Ticket
