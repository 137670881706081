import React, { createContext, useState, useEffect } from 'react';

const WalletContext = createContext();
const UserIdContext = createContext();



const AppProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(()=>localStorage.getItem('walletAddress'||''));
  const [userId, setUserId] = useState(() => localStorage.getItem('userId') || '');



  useEffect(() => {
    if (userId) {
      localStorage.setItem('userId', userId);
    } else {
      localStorage.removeItem('userId');
    }
    if(walletAddress){
      localStorage.setItem("walletAddress",walletAddress)
    }else{
      localStorage.removeItem('walletAddress');
    }
  }, [userId,walletAddress]);

  return (
    <WalletContext.Provider value={{ walletAddress, setWalletAddress }}>
      <UserIdContext.Provider value={{ userId, setUserId }}>
        {children}
      </UserIdContext.Provider>
    </WalletContext.Provider>
  );
};

export { AppProvider, WalletContext, UserIdContext };

