import React from 'react'

const TicketHistory = () => {
  return (
    <>
      <section className='sec01_pool1'>
        <div className='container'>
        <h2 className='sec_heading_h2 w josefin text-center'>Ticket History</h2>
            <div className='sec2_box'>
            <div className='d-flex table_pagi'>
                            <div className='flex1'>
                                <h3 className='table_sub_title josefin w' >( Total : 164 )</h3>
                            </div>
                            <div className='flex2'>
                                <nav aria-label="Page navigation example">
                                <ul class="pagination pagi_nation">
                                    <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Previous">
                                        <span className='b' aria-hidden="true b">&laquo;</span>
                                    </a>
                                    </li>
                                    <li class="page-item pagi_nation_item"><a class="page-link b josefin" href="#">1</a></li>
                                    <li class="page-item pagi_nation_item"><a class="page-link b josefin" href="#">2</a></li>
                                    <li class="page-item pagi_nation_item"><a class="page-link b josefin" href="#">3</a></li>
                                    <li class="page-item pagi_nation_item">
                                    <a class="page-link" href="#" aria-label="Next">
                                        <span className='b' aria-hidden="true">&raquo;</span>
                                    </a>
                                    </li>
                                </ul>
                                </nav>
                            </div>
                </div>
                <div className='over'>
                <table className='MyTeamDirects_table'>
                    <tr className='table_row'>
                        <th className='table_heading josefin w'> S No.</th>
                        <th className='table_heading josefin w'>ISSUE	</th>
                        <th className='table_heading josefin w'>ID NUMBER	</th>
                        <th className='table_heading josefin w'>HASH	</th>
                        <th className='table_heading josefin w'>FILE</th>
                        <th className='table_heading josefin w'>REMARK	</th>
                        <th className='table_heading josefin w'>STATUS	</th>
                        <th className='table_heading josefin w'>DATE</th>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>1</td>
                        <td className='table_data inter w'></td>
                        <td className='table_data inter w'></td>
                        <td className='table_data inter w'></td>
                        <td className='table_data inter w'></td>
                        <td className='table_data inter w'></td>
                        <td className='table_data inter w'></td>
                        <td className='table_data inter w'></td>
                    </tr>
                </table>
                </div>
            </div>
        </div>
        </section>
    </>
  )
}

export default TicketHistory
