import React, { useState, useEffect } from 'react'
import Connectw from '../Images/Connect-wallet.png'
import user from '../Images/Connect-wallet.png'
import { Web3 } from "web3"
import axios from 'axios'
import toast from 'react-hot-toast';
import { base_url } from './Base_url'
import { useContext } from 'react'
import { WalletContext, UserIdContext } from '../context/walletContext'
import { ColorRing } from 'react-loader-spinner';
import { NavLink, useNavigate } from 'react-router-dom'
import { Pagination } from "antd";
const ConnectWallet = () => {
    const [web3, setWeb3] = useState(null)
    const [isValue, setValue] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    const { setWalletAddress } = useContext(WalletContext);
    const { setUserId } = useContext(UserIdContext)
    const limit = 10;
    const [total, setTotal] = useState([]);
    const [totalPage, settotalPage] = useState(1)
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [data, setData] = useState([]);
    const navigate = useNavigate()
    const [member, setMember] = useState(0);
    const [turn, setTurn] = useState(0);
    console.log('turn', turn);
    useEffect(() => {
        if (window.ethereum) {
            const web3Instance = new Web3(window.ethereum)
            setWeb3(web3Instance)
        }
    }, [])

    //connect wallet function

    const ConnectWallet = async () => {
        console.log("for connect wallet");
        if (window.ethereum) {
            const web3Instance = new Web3(window.ethereum)
            setWeb3(web3Instance)
            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                console.log("MetaMask connected successfully");
                const walletAddress = await web3.eth.getAccounts()
                // console.log("walletAddress.......",walletAddress[0]);
                let address = walletAddress[0]
                setWalletAddress(walletAddress[0])

                let config = { 
                    mode: 'no-cors',
                    method: 'get',
                    url: `${base_url}/Api/user_login/${address}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                };

                axios.request(config)
                    .then((response) => {
                        if (response.data.success === 1) {
                            setUserId(response.data.user.user_id)
                        } else {
                            // alert(response.data.message)
                            toast.error(response.data.message)
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                        setError(error);
                        toast.error("Error fetching data: " + error)
                    });
                navigate("/dashboard")
            } catch (error) {
                console.error("user denied account access");
            }
        }
    }


    const getUser = () => {
        if (!isValue) {
            // Handle the case where input is empty
            console.log("pls enter the id .");
            return;
        }


        console.log('clicked on function', isValue);
        let config = {
            mode: 'no-cors',
            method: 'get',
            url: `${base_url}/Api/user_login/${isValue}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        };

        axios.request(config)
            .then((response) => {
                // console.log("Response:", response.data.user.user_id);
                setUserId(response.data.user.user_id)
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                toast.error(error.message)
                setError(error);
            });
        navigate("/dashboard")
    };


    console.log('data-=-=-=-=-=-=-=', data);
    useEffect(() => {
        const fetchDataAsync = async () => {
            setIsLoading(true);
            try {
                await fetchData();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error("Error fetching data:", error);
                setError(error);
            }
        };

        fetchDataAsync();
    }, []);

    const handlePaginationChange = (page) => {
        const newSkip = (page - 1) * limit;
        setPage(page);
        setSkip(newSkip);
        fetchData(newSkip); // Call fetchData with the newSkip value
    };

    const fetchData = (skip = 0) => {
        setIsLoading(true);

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: ` ${base_url}/Api/landing_data/` + skip,
            headers: {
                'Content-Type': "application/x-www-form-urlencoded"
            },
            // data: data
        };

        return axios.request(config)
            .then((response) => {
                console.log(response);
                (JSON.stringify(response))
                // console.log("Response:", response.data.income_list);
                setTotal(response.data.records_count)
                setMember(response.data.members_received)
                setData(response.data.records);
                settotalPage(Math.ceil(response.data.records_count / limit))
                setTurn(response.data.turnover_usdt)
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                toast.error('Error fetching data:')
                setError(error);
            }).finally(() => {
                setIsLoading(false);
            });

    }

    return (
        <>
            <section className='sec01_con_w'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-5 banner_img_col'>
                            <img className='con-wallet' src={Connectw} />
                        </div>
                        <div className='col-lg-7 col-md-7 align-self text-center'>
                            <h1 className='landing_banner_heading w josefin'>Welcome to ALPHAWIN</h1>
                            <p className='banner_text inter'>Connect your wallet to start working. First time here? Watch a tutorial to learn more</p>
                            <NavLink className='btn_m banner_btn josefin w' onClick={ConnectWallet}>Connect Wallet</NavLink>
                        </div>

                    </div>
                </div>
            </section>

            <section className='sec02_con_w'>
                <div className='container'>
                    <h2 className='sec_heading_h2 w josefin text-center'>Account Preview</h2>
                    <p className='banner_text inter text_max text-center'>Look up any Roksa USDT member account in preview mode. Enter ID or USDT address to preview or click Demo to view a random account.</p>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='sec2_box'>
                                <h5 className='partners-title w josefin'>Don’t know any ID?</h5>
                                <form className='form_join'>
                                    <input className='form_input inter w' type='text' placeholder='' onChange={(e) => setValue(e.target.value)} required />
                                    {/* <input className='btn_m form_btn josefin w mt-0 ms-3' type='submit' value='Submit' onClick={getUser(value)} /> */}
                                    <button className='btn_m form_btn josefin w mt-0 ms-3 pos' onClick={getUser}  > submit</button>
                                </form>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='sec2_box'>
                                <h5 className='partners-title w josefin'>Don’t know any ID?</h5>
                                <a href='/' className='btn_m check_demo josefin w'> Check demo</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sec03_con_w'>
                <div className='container'>
                    {/* <h2 className='sec_heading_h2 w josefin text-center'>.</h2> */}
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='sec2_box mt-2'>
<div className='overi'>
                                <div className='over1'>

                                    {isLoading ? (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
                                            <ColorRing colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']} height={65} width={65} />
                                        </div>
                                    ) : (
                                        <table className='con_table'>
                                            {data?.length > 0 ? (
                                                data?.map((item, index) => {
                                                    const serialNumber = (page - 1) * limit + index + 1;
                                                    return (
                                                        <tr key={index} className='table_row'>
                                                            <td className='table_data inter w'><img className='table_user me-2' src={user} /> {item.eth_address}</td>
                                                            <td className='table_data inter w text-end'>
                                                                <a href={`https://bscscan.com/address/${item.eth_address}`} target="_blank">
                                                                    <i class="fa-solid fa-arrow-up-right-from-square w arrow-up-right lc me-2"></i>
                                                                </a>
                                                                {item.created_at}
                                                            </td>
                                                            {/* <td>
                                                                            <a href={`https://bscscan.com/tx/${val.blockHash}`} target="_blank" >
                                                                                {val.blockHash ? val.blockHash.slice(0, 30) : 'None'}
                                                                            </a>
                                                                        </td> */}
                                                        </tr>

                                                    )
                                                })) : (
                                                <tr>
                                                    <td className="table_data inter w">Data not found</td>
                                                </tr>
                                            )}
                                        </table>
                                    )}
                                    <div className='flex2 io2' >


                                        <Pagination
                                            style={{ cursor: "pointer" }}
                                            className="mx-auto"
                                            current={page}
                                            total={totalPage * limit} // Ensure this reflects the total count of items
                                            pageSize={limit}
                                            showLessItems
                                            showMoreItems
                                            onChange={handlePaginationChange}
                                            showSizeChanger={false}
                                            showQuickJumper={false}
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='sec2_box mt-2'>
                                <h5 className='partners-title w josefin'>Members received</h5>
                                <p className='banner_text inter g mb-0'>{member}</p>
                            </div>
                            <div className='sec2_box mt-2'>
                                <h5 className='partners-title w josefin'>Members Total</h5>
                                <p className='banner_text inter g mb-0'>{total}</p>
                            </div>
                            <div className='sec2_box mt-2'>
                                <h5 className='partners-title w josefin'>Turnover, USDT</h5>
                                <p className='banner_text inter g mb-0'>{turn}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ConnectWallet
