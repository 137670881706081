import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { UserIdContext } from '../context/walletContext';
import { Pagination } from 'antd';
import { ColorRing } from 'react-loader-spinner';
import { base_url } from './Base_url'
const LevelDetail = () => {
  const { userId } = useContext(UserIdContext);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [level, setLevel] = useState(0);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const levelParam = queryParams.get('level');
    setLevel(levelParam || 0); // Setting level from query params or default to 0

    // Fetch data only if userId is defined
    if (userId) {
      fetchData(userId, levelParam || 0); // Pass levelParam or 0 as default
    }
  }, [userId, level, location.search]);

  const fetchData = (userId, level, skip = 0) => {
    setIsLoading(true);
    axios
      .get(`${base_url}/Api/levelDetails/${userId}/${level}/${skip}`)
      .then((response) => {
        setData(response.data?.users || []);
        setTotalPage(Math.ceil(response.data?.records_count / limit) || 1);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePaginationChange = (page) => {
    const newSkip = (page - 1) * limit;
    setPage(page);
    setSkip(newSkip);
    fetchData(userId, level, newSkip);
  };

  return (
    <>
      <section className="sec01_pool1">
        <div className="container">
          <h2 className="sec_heading_h2 w josefin text-center">Level Income</h2>
          <div className="sec2_box">
            <div className="d-flex table_pagi">
              <div className="flex1"></div>
              <div className="flex2">
                <Pagination
                  style={{ cursor: 'pointer' }}
                  className="mx-auto"
                  current={page}
                  total={totalPage * limit}
                  pageSize={limit}
                  showLessItems
                  showMoreItems
                  onChange={handlePaginationChange}
                  showSizeChanger={false}
                  showQuickJumper={false}
                />
              </div>
            </div>
            <div className="over">
              {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px' }}>
                  <ColorRing colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']} height={65} width={65} />
                </div>
              ) : (
                <table className="MyTeamDirects_table">
                  <thead>
                    <tr className="table_row">
                      <th className="table_heading josefin w">S No.</th>
                      <th className="table_heading josefin w">USER ID</th>
                      <th className="table_heading josefin w">NAME</th>
                      <th className="table_heading josefin w">ETH ADDRESS</th>
                      <th className="table_heading josefin w">DATE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                    data.map((item, index) => {
                      const serialNumber = (page - 1) * limit + index + 1;
                      return (
                        <tr key={index} className="table_row">
                          <td className="table_data inter w">{serialNumber}</td>
                          <td className="table_data inter w">{item.userinfo.user_id}</td>
                          <td className="table_data inter w">{item.userinfo.name}</td>
                          <td className="table_data inter w">{item.userinfo.eth_address}</td>
                          <td className="table_data inter w">{item.userinfo.created_at}</td>
                        </tr>
                      );
                    })) : (
                        <tr>
                          <td className="table_data inter w">Data not found</td>
                        </tr>
                      )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LevelDetail;
