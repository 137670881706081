import React from 'react'

import banner_img from '../Images/banner.png'
import landing_sec02 from '../Images/bg-gradient-img.png'
import logo from '../Images/logo.png'
import Meta from '../Images/Meta.png'
import BNB from '../Images/BNB.png'
import Trust from '../Images/Trust.png'
import Contact from '../Images/Contact.png'

import { NavLink } from 'react-router-dom'

const Landing = () => {
  return (
    <>
      <section className='landing_sec01 '>
            <div className='container'>
                <div className='row col-reverse'>
                    <div className='col-lg-7 col-md-7 align-self baner_col1'>
                        <h5 className='sub_title josefin'>Welcome ALPHAWIN</h5>
                        <h1 className='landing_banner_heading w josefin'>Join ALPHAWIN Web , A Decentralized Networking Platform On Binance Smart Chain,</h1>
                        <p className='banner_text inter'>World's First VIP CTO Plan in which all the joining and VIP funds are stored in smart contact and VIP Holder can withdraw their income directly from smart contract.</p>
                        <NavLink className='btn_m banner_btn josefin w' to="/ConnectWallet">Connect Wallet</NavLink>
                    </div>
                    <div className='col-lg-5 col-md-5 banner_img_col'>
                        <img className='banner_img' src={banner_img} />
                    </div>
                </div>
            </div>
        </section>
        
        <section className='landing_sec02'>
            {/* <img className='landing_sec02_img' src={landing_sec02} /> */}
            <div className='container'>
                <h2 className='sec_heading_h2 w josefin text-center'>How Do I Start Earning With Alphawin</h2>
                <div className='row start_row'>
                        <div className='col-lg-4 col-md-4 col-sm-6'>
                            <div className='tab_box text-center'>
                                {/* <img className='tab_box_img' src={Meta} /> */}
                                <h5 className='s_box_title josefin w'>Create Wallet</h5>
                                <p className='s_box_text inter mb-0 w'>Create a Metamask or Trust Wallet. Alphawin works with Metamask and Trust wallet.</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6'>
                            <div className='tab_box text-center'>
                                {/* <img className='tab_box_img' src={BNB} /> */}
                                <h5 className='s_box_title josefin w'>Register</h5>
                                <p className='s_box_text inter mb-0 w'>Register on the site.If someone invited you, use his referral link. Check that your upline is correct.</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6'>
                            <div className='tab_box text-center'>
                                {/* <img className='tab_box_img' src={Trust} /> */}
                                <h5 className='s_box_title josefin w'>Activate</h5>
                                <p className='s_box_text inter mb-0 w'>Activate Your Account in Alphawin systems to increase your income.</p>
                            </div>
                        </div>
                    </div>
            </div>
        </section>

        <section className='landing_sec03'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 text-center align-self'>
                        <img className='landing_sec03_img' src={logo} />   
                    </div>
                    <div className='col-lg-6 align-self'>
                        <h5 className='sub_title josefin'> WHO WE ARE </h5>
                        <h2 className='sec_heading_h2 w josefin'>Alphawin is a pioneering decentralized networking platform leveraging smart contracts on the Binance Smart Chain.</h2>
                        <p className='banner_text inter mb-0'>It fosters global connectivity, unlocking vast opportunities within the emergent economic landscape. By seamlessly connecting individuals worldwide, Alphawin catalyzes the evolution of a novel financial ecosystem, offering boundless potential for innovation and growth.</p>
                    </div>
                </div>
            </div>
        </section>

        <section className='landing_sec02'>
           
            <div className='container'>
                <h2 className='sec_heading_h2 w josefin text-center'>Our Partners</h2>
                <div className='row start_row'>
                        <div className='col-lg-4 col-md-4 col-sm-6'>
                            <div className='text-center'>
                                <img className='par_box_img' src={Meta} />
                                <a className='s_box_title josefin w d-block' href='/'>Meta Mask</a>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6'>
                            <div className='text-center'>
                                <img className='par_box_img' src={BNB} />
                                <a className='s_box_title josefin w d-block' href='/'>BNB Smart Chain</a>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6'>
                            <div className='text-center'>
                                <img className='par_box_img' src={Trust} />
                                <a className='s_box_title josefin w d-block' href='/'>Trust Wallet</a>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
        <section className='sec02_ep'>
        <div className='container'>
        <h2 className='sec_heading_h2 w josefin text-center'> Contact</h2>
            <div className='row'>
                <div className='col-lg-6 col-md-6 text-center align-self'>
                    <img src={Contact} className='for_img' />
                </div>
                <div className='col-lg-6 col-md-6'>
                    <div className='sec2_box'>
                        <form className='edit_pro'>
                            <label className='form_label josefin w'>Name </label>
                            <input className='form_input inter w' type='text' placeholder='' />
                            <label className='form_label josefin w'>Email </label>
                            <input className='form_input inter w' type='email' placeholder='' />
                            <label className='form_label josefin w'>Mobile No. </label>
                            <input className='form_input inter w' type='tel' placeholder='' />
                            <label className='form_label josefin w'>Massage</label>
                            <textarea className='form_input inter w' rows={4}></textarea>
                            <input className='btn_m form_btn josefin w' type='submit' value='Sumit' />
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </section>
    </>
  )
}

export default Landing
