import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import login from '../Images/Login025.png';
import { WalletContext } from '../context/walletContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { base_url } from './Base_url'
const Login = () => {
  const { walletAddress } = useContext(WalletContext);
  const [user, setUser] = useState('');
  const [sponser, setSponser] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const sponsorId = searchParams.get('sponser_id');
    console.log('sponsorId',sponsorId);
    if (sponsorId) {
      setSponser(sponsorId);
    }
  }, [searchParams]);

  const registration = (e) => {
    e.preventDefault();
    console.log('clicked on function');

    const config = {
      method: 'post',
      url: `${base_url}Api/register_user`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: new URLSearchParams({
        sponser_id: sponser,
        name: user,
        eth_address: walletAddress,
      }).toString(),
    };

    console.log("config", config);

    axios(config)
      .then((response) => {
        console.log("response", response.data);
        if (response.data.success === 1) {
          navigate("/ConnectWallet");
          toast.success(response.data.message);
        } else {
          alert(response.data.message);
          toast.error(response.data.message);

        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
      });
  };

  return (
    <>
      <section className='sec01_login'>
        <div className='container'>
          <div className='login_box'>
            <div className='row login_row'>
              <div className='col-lg-6 col-md-6 text-center align-self'>
                <img src={login} className='w-55' />
              </div>
              <div className='col-lg-6 col-md-6'>
                <div className='sec2_box'>
                  <form className='edit_pro'>
                    <label className='form_label josefin w'>Sponsor Id</label>
                    <input
                      className='form_input inter w'
                      type='text'
                      placeholder=''
                      value={sponser}
                      onChange={(e) => setSponser(e.target.value)}
                    />
                    <label className='form_label josefin w'>Name</label>
                    <input
                      className='form_input inter w'
                      type='text'
                      placeholder=''
                      onChange={(e) => setUser(e.target.value)}
                    />
                    <label className='form_label josefin w'>User Id</label>
                    <input
                      className='form_input inter w'
                      type='text'
                      placeholder=''
                      value={walletAddress}
                      readOnly
                    />
                    <button
                      className='btn_m form_btn josefin w mt-2'
                      type='submit'
                      value='Register Now'
                      onClick={registration}
                    >
                      Register Now
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;

