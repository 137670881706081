import React from 'react'
import formi from '../Images/editp.png'

const EditProfile = () => {
  return (
    <>
    <section className='sec02_ep'>
        <div className='container'>
        <h2 className='sec_heading_h2 w josefin text-center'> My Personal Information</h2>
            <div className='row'>
                <div className='col-lg-6 col-md-6 text-center'>
                    <img src={formi} className='form_img' />
                </div>
                <div className='col-lg-6 col-md-6'>
                    <div className='sec2_box'>
                        <form className='edit_pro'>
                            <label className='form_label josefin w'>Name </label>
                            <input className='form_input inter w' type='text' placeholder='' />
                            <label className='form_label josefin w'>Contact Number </label>
                            <input className='form_input inter w' type='tel' placeholder='' />
                            <label className='form_label josefin w'>Email </label>
                            <input className='form_input inter w' type='email' placeholder='' />
                            <label className='form_label josefin w'>Status </label>
                            <input className='form_input inter w' type='text' placeholder='' />
                            <input className='btn_m form_btn josefin w' type='submit' value='Update' />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default EditProfile