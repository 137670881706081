import React,{useState,useContext,useEffect} from 'react'
import axios from 'axios';
import { UserIdContext } from '../context/walletContext';

const PoolChart = () => {
  return (
    <>
     <section className='sec01_pool1'>
        <div className='container'>
        <h2 className='sec_heading_h2 w josefin text-center'>Pool Chart</h2>
            <div className='sec2_box'>
            <div className='d-flex table_pagi'>
                            <div className='flex1'>
                                {/* <h3 className='table_sub_title josefin w' >( Total : 387.164 )</h3> */}
                            </div>
                            <div className='flex2'>
                                {/* <nav aria-label="Page navigation example">
                                <ul class="pagination pagi_nation">
                                    <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Previous">
                                        <span className='b' aria-hidden="true b">&laquo;</span>
                                    </a>
                                    </li>
                                    <li class="page-item pagi_nation_item"><a class="page-link b josefin" href="#">1</a></li>
                                    <li class="page-item pagi_nation_item"><a class="page-link b josefin" href="#">2</a></li>
                                    <li class="page-item pagi_nation_item"><a class="page-link b josefin" href="#">3</a></li>
                                    <li class="page-item pagi_nation_item">
                                    <a class="page-link" href="#" aria-label="Next">
                                        <span className='b' aria-hidden="true">&raquo;</span>
                                    </a>
                                    </li>
                                </ul>
                                </nav> */}
                            </div>
                </div>
                <h1 style={{color:"white", textAlign:"center", padding:"20px" }} className='m-5 text-center'> COMMING SOON .......</h1>
                {/* <div className='over'> */}
                {/* <table className='MyTeamDirects_table'>
                    <tr className='table_row'>
                        <th className='table_heading josefin w'> S No.</th>
                        <th className='table_heading josefin w'>USER ID	</th>
                        <th className='table_heading josefin w'>UPLINE ID	</th>
                        <th className='table_heading josefin w'>TEAM</th>
                        <th className='table_heading josefin w'>ENTER DATE</th>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>1</td>
                        <td className='table_data inter w'>40481</td>
                        <td className='table_data inter w'>11111</td>
                        <td className='table_data inter w'>2</td>
                        <td className='table_data inter w'>2022-03-22 11:30:12</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>2</td>
                        <td className='table_data inter w'>40481</td>
                        <td className='table_data inter w'>11111</td>
                        <td className='table_data inter w'>2</td>
                        <td className='table_data inter w'>2022-03-22 11:30:12</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>3</td>
                        <td className='table_data inter w'>40481</td>
                        <td className='table_data inter w'>11111</td>
                        <td className='table_data inter w'>2</td>
                        <td className='table_data inter w'>2022-03-22 11:30:12</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>4</td>
                        <td className='table_data inter w'>40481</td>
                        <td className='table_data inter w'>11111</td>
                        <td className='table_data inter w'>2</td>
                        <td className='table_data inter w'>2022-03-22 11:30:12</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>5</td>
                        <td className='table_data inter w'>40481</td>
                        <td className='table_data inter w'>11111</td>
                        <td className='table_data inter w'>2</td>
                        <td className='table_data inter w'>2022-03-22 11:30:12</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>6</td>
                        <td className='table_data inter w'>40481</td>
                        <td className='table_data inter w'>11111</td>
                        <td className='table_data inter w'>2</td>
                        <td className='table_data inter w'>2022-03-22 11:30:12</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>7</td>
                        <td className='table_data inter w'>40481</td>
                        <td className='table_data inter w'>11111</td>
                        <td className='table_data inter w'>2</td>
                        <td className='table_data inter w'>2022-03-22 11:30:12</td>
                    </tr>
                </table> */}
                {/* </div> */}
            </div>
        </div>
    </section>
    </>
  )
}

export default PoolChart