

import React, { useState, useContext, useEffect } from 'react';
import tree1 from '../Images/tree1.webp';
import axios from 'axios';
import { UserIdContext } from '../context/walletContext';
import left from '../Images/left.png'
import { base_url } from './Base_url'
const Teamtree = () => {
    const { userId } = useContext(UserIdContext); // Assuming userId is provided by the context
    const [currentUserId, setCurrentUserId] = useState(userId); // Local state to manage current node
    const [team, setTeam] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [history, setHistory] = useState([]); // History of visited user ids

    useEffect(() => {
        fetchData(currentUserId); // Fetch data for initial/current userId
    }, [currentUserId]); // Fetch data whenever currentUserId changes

    const fetchData = async (userId) => {
        setIsLoading(true);
      
        try {
            const response = await axios.get(`${base_url}/Api/pool_tree/${userId}`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            
            console.log("Response:", response.data);
            setTeam(response.data.users);

            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error);
            setIsLoading(false);
        }
    };

    const handleClick = (newUserId) => {
        // Add currentUserId to history if it's not already there
        if (!history.includes(currentUserId)) {
            setHistory([...history, currentUserId]);
        }
        setCurrentUserId(newUserId); // Update local state for currentUserId
    };

    const handleBack = () => {
        if (history.length > 0) {
            const lastUserId = history[history.length - 1];
            setHistory(history.slice(0, -1)); // Remove last item from history
            setCurrentUserId(lastUserId); // Navigate back to the last visited userId
        }
    };

    return (
        <>
            <section className='sec01_pool1'>
                <div className='container'>
                    <h2 className='sec_heading_h2 w josefin text-center'>Affiliates / My Referral</h2>
                    <div className='sec2_box'>
                        <div className="container">
                            <div className="horizontal">
                                <div className="verticals twelve">
                                    <section className="management-tree">
                                        <div className="mgt-container">
                                            <div className="mgt-wrapper">
                                            {history.length > 0 && (
    <div onClick={handleBack} style={{ cursor: 'pointer' }}>
        <img className='back' src={left}  alt="Back" />
    
    </div>
)}
                                                <div className="mgt-item">
                                                    
                                                    <div className="mgt-item-parent">
                                                        <div className="person">
                                                            <img src={tree1} alt="" />
                                                            <p className="name">{currentUserId}</p>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="mgt-item-children">
                                                   
                                                        {team?.map((user) => (
                                                            <div className="mgt-item-child" key={user.user_id}>
                                                                <div className="mgt-item">
                                                                    <div className="mgt-item-parent">
                                                                        <div className="person">
                                                                            <img src={tree1} alt="" />
                                                                            <p className="name" onClick={() => handleClick(user.user_id)}>
                                                                                {user.user_id} - {user.user_info.name}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="mgt-item-children">
                                                                        {user.level_2?.map((level2User) => (
                                                                            <div className="mgt-item-child" key={level2User.user_id}>
                                                                                <div className="person">
                                                                                    <img src={tree1} alt="" />
                                                                                    <p className="name" onClick={() => handleClick(level2User.user_id)}>
                                                                                        {level2User.user_id}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    
                                                </div>
                                          
                                            </div>
                                                  {/* {history.length > 0 && (
                <button onClick={handleBack}>Back</button>
            )} */}
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Back Button */}
            {/* {history.length > 0 && (
                <button onClick={handleBack}>Back</button>
            )} */}
        </>
    );
};

export default Teamtree;
