import React from 'react'
import a1 from '../Images/a1.jpg'
import a2 from '../Images/a2.jpg'
import a3 from '../Images/a3.jpg'
import a4 from '../Images/a4.jpg'
import a5 from '../Images/a5.jpg'
import a6 from '../Images/a6.jpg'
import a7 from '../Images/a7.jpg'
import a8 from '../Images/a8.jpg'
import a9 from '../Images/a9.jpg'
import a10 from '../Images/a10.jpg'
import a11 from '../Images/a11.jpg'
import a12 from '../Images/a12.jpg'
import a13 from '../Images/a13.jpg'
import a14 from '../Images/a14.jpg'
import a15 from '../Images/a15.jpg'
import a16 from '../Images/a16.jpg'
const Pdf  = () => {
  return (
    <section className='sec01_pool1'>
    <div className='container'>
        <h2 className='sec_heading_h2 w josefin text-center'> PDF</h2>
     
                
                <img className='ai' src={a1} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a2} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a3} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a4} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a5} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a6} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a7} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a8} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a9} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a10} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a11} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a12} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a13} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a14} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a15} alt='pdf1' />
                <br/>
                <br/>
                <img className='ai' src={a16} alt='pdf1' />
                
            
    </div>
</section>
  )
}

export default Pdf 
