import React from 'react'

const MyTeamDirects = () => {
  return (
    <>
    <section className='sec01_pool1'>
        <div className='container'>
        <h2 className='sec_heading_h2 w josefin text-center'>My Team Directs</h2>
            <div className='sec2_box'>
            <div className='d-flex table_pagi'>
                            <div className='flex1'>
                                <h3 className='table_sub_title josefin w' >( Total : 387.164 )</h3>
                            </div>
                            <div className='flex2'>
                                <nav aria-label="Page navigation example">
                                <ul class="pagination pagi_nation">
                                    <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Previous">
                                        <span className='b' aria-hidden="true b">&laquo;</span>
                                    </a>
                                    </li>
                                    <li class="page-item pagi_nation_item"><a class="page-link b josefin" href="#">1</a></li>
                                    <li class="page-item pagi_nation_item"><a class="page-link b josefin" href="#">2</a></li>
                                    <li class="page-item pagi_nation_item"><a class="page-link b josefin" href="#">3</a></li>
                                    <li class="page-item pagi_nation_item">
                                    <a class="page-link" href="#" aria-label="Next">
                                        <span className='b' aria-hidden="true">&raquo;</span>
                                    </a>
                                    </li>
                                </ul>
                                </nav>
                            </div>
                </div>
                <div className='over'>
                <table className='MyTeamDirects_table'>
                    <tr className='table_row'>
                        <th className='table_heading josefin w'> S No.</th>
                        <th className='table_heading josefin w'>USER ID	</th>
                        <th className='table_heading josefin w'>NAME</th>
                        <th className='table_heading josefin w'>PACKAGE</th>
                        <th className='table_heading josefin w'>LEVEL</th>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>1</td>
                        <td className='table_data inter w'>40649</td>
                        <td className='table_data inter w'>Irfan</td>
                        <td className='table_data inter w'>0</td>
                        <td className='table_data inter w'>1</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>2</td>
                        <td className='table_data inter w'>40649</td>
                        <td className='table_data inter w'>Irfan</td>
                        <td className='table_data inter w'>0</td>
                        <td className='table_data inter w'>1</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>3</td>
                        <td className='table_data inter w'>40649</td>
                        <td className='table_data inter w'>Irfan</td>
                        <td className='table_data inter w'>0</td>
                        <td className='table_data inter w'>1</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>4</td>
                        <td className='table_data inter w'>40649</td>
                        <td className='table_data inter w'>Irfan</td>
                        <td className='table_data inter w'>0</td>
                        <td className='table_data inter w'>1</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>5</td>
                        <td className='table_data inter w'>40649</td>
                        <td className='table_data inter w'>Irfan</td>
                        <td className='table_data inter w'>0</td>
                        <td className='table_data inter w'>1</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>6</td>
                        <td className='table_data inter w'>40649</td>
                        <td className='table_data inter w'>Irfan</td>
                        <td className='table_data inter w'>0</td>
                        <td className='table_data inter w'>1</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>7</td>
                        <td className='table_data inter w'>40649</td>
                        <td className='table_data inter w'>Irfan</td>
                        <td className='table_data inter w'>0</td>
                        <td className='table_data inter w'>1</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>8</td>
                        <td className='table_data inter w'>40649</td>
                        <td className='table_data inter w'>Irfan</td>
                        <td className='table_data inter w'>0</td>
                        <td className='table_data inter w'>1</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>9</td>
                        <td className='table_data inter w'>40649</td>
                        <td className='table_data inter w'>Irfan</td>
                        <td className='table_data inter w'>0</td>
                        <td className='table_data inter w'>1</td>
                    </tr>
                    <tr className='table_row'>
                        <td className='table_data inter w'>10</td>
                        <td className='table_data inter w'>40649</td>
                        <td className='table_data inter w'>Irfan</td>
                        <td className='table_data inter w'>0</td>
                        <td className='table_data inter w'>1</td>
                    </tr>
                </table>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default MyTeamDirects