// import React, { useState, useEffect, useContext } from 'react'
// import axios from 'axios';
// import { Pagination } from "antd";  // Import Ant Design Pagination

// import { UserIdContext } from '../context/walletContext';
// import { ColorRing } from 'react-loader-spinner';
// import { base_url } from './Base_url'
// const Tomaxthree = () => {
//     const [data, setData] = useState([]);
//     const [total, setTotal] = useState([]);
//     const [totalPage, settotalPage] = useState(1)
//     const [page, setPage] = useState(1);
//     const [skip, setSkip] = useState(0);
//     const limit = 10;
//     const [error, setError] = useState(null);
//     const { userId } = useContext(UserIdContext)
//     const [isPage, setIsPage] = useState(0)
//     const [isLoading, setIsLoading] = useState(true);
//     useEffect(() => {
//         const fetchDataAsync = async () => {
//             setIsLoading(true);
//             try {
//                 await fetchData();
//                 setIsLoading(false);
//             } catch (error) {
//                 setIsLoading(false);
//                 console.error("Error fetching data:", error);
//                 setError(error);
//             }
//         };

//         fetchDataAsync();
//     }, []);

//     const handlePaginationChange = (page) => {
//         const newSkip = (page - 1) * limit;
//         setPage(page);
//         setSkip(newSkip);
//         fetchData(newSkip); // Call fetchData with the newSkip value
//     };
    


//     const fetchData = (skip = 0) => {
//         setIsLoading(true);
//         let config = {
//             method: 'get',
//             maxBodyLength: Infinity,
//                url: `${base_url}/Api/upgrade_pool_timex_records/${userId}/` + skip,
//             headers: {
//                 'Content-Type': "application/x-www-form-urlencoded"
//             },
//             // data: data
//         };

//         return axios.request(config)
//             .then((response) => {
//                 console.log(response);
//                 (JSON.stringify(response))
//                 console.log("Response:", response.data.income_list);
//                 // setOther(response.data)
//                 settotalPage(Math.ceil(response.data.records_count / limit))

//                 setTotal(response.data)
//                 setData(response.data.transactions);
//             })
//             .catch((error) => {
//                 console.error("Error fetching data:", error);
//                 setError(error);
//             }).finally(() => {
//                 setIsLoading(false);
//             });
//     }

//     return (
//         <>
//             <section className='sec01_pool1'>
//                 <div className='container'>
//                     <h2 className='sec_heading_h2 w josefin text-center'> 3.C Tomax Income                    </h2>
//                     <div className='sec2_box'>
//                         <div className='d-flex table_pagi'>
//                             <div className='flex1'>
//                                 <h3 className='table_sub_title josefin w' >( Total : ${total.claimed_tokens} )</h3>
//                             </div>
//                             <div className='flex2'>


//                             <Pagination
//                                     style={{ cursor: "pointer" }}
//                                     className="mx-auto"
//                                     current={page}
//                                     total={totalPage * limit} // Ensure this reflects the total count of items
//                                     pageSize={limit}
//                                     showLessItems
//                                     showMoreItems
//                                     onChange={handlePaginationChange}
//                                     showSizeChanger={false}
//                                     showQuickJumper={false}
//                                 />
//                             </div>
//                         </div>
//                         <div className='over'>
//                         {isLoading ? (
//                                 <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
//                                     <ColorRing colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']} height={65} width={65} />
//                                 </div>
//                             ) : (
//                             <table className='MyTeamDirects_table'>
//                                 <tr className='table_row'>
//                                     <th className='table_heading josefin w'> S No.</th>
//                                     <th className='table_heading josefin w'>AMOUNT	</th>
//                                     <th className='table_heading josefin w'>TYPE</th>
//                                     <th className='table_heading josefin w'>DESCRIPTION	</th>
//                                     <th className='table_heading josefin w'>DATE</th>
//                                 </tr>
//                                 {data?.length > 0 ? (
//                                 data?.map((item, index) => {
//                                     const serialNumber = (page - 1) * limit + index + 1;
//                                     return (
//                                         <tr key={index} className='table_row'>
//                                             <td className='table_data inter w'>{serialNumber}</td>
//                                             <td className='table_data inter w'>{item.amount}</td>
//                                             <td className='table_data inter w'>{item.type}</td>
//                                             <td className='table_data inter w'>{item.description}	</td>
//                                             <td className='table_data inter w'>{item.created_at}</td>
//                                         </tr>
//                                     )
//                                 })):(
//                                     <tr>
//                                     <td className="table_data inter w">Data not found</td>
//                                   </tr>
//                                 )}
//                             </table>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     )
// }

// export default Tomaxthree

import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { UserIdContext } from '../context/walletContext';
import { Pagination } from "antd";
import { ColorRing } from 'react-loader-spinner';

import { base_url } from './Base_url'
const Tomaxthree = () => {
    const [transactionsData, setTransactionsData] = useState([]);
    const [internalData, setInternalData] = useState([]);
    const [total, setTotal] = useState({});
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const limit = 10;
    const [claimedTokens, setClaimedTokens] = useState('');
    const [activeTab, setActiveTab] = useState('transactions');
    const [error, setError] = useState(null);
    const { userId } = useContext(UserIdContext);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [activeTab, skip]);

    const handlePaginationChange = (page) => {
        const newSkip = (page - 1) * limit;
        setPage(page);
        setSkip(newSkip);
    };
// https://alphawin.io/stacking//Api/upgrade_pool_timex_records/34069/0

    const fetchData = () => {
        setIsLoading(true);
        let url;
        if (activeTab === 'transactions') {
            url = `${base_url}Api/upgrade_pool_timex_records/${userId}/` + skip
        } else if (activeTab === 'internal') {
            url = `${base_url}Api/upgrade_pool_timex_claim_history/${userId}/` + skip; // Use the actual URL for the internal transactions API
        }

        axios.get(url, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            maxBodyLength: Infinity,
        })
        .then((response) => {
            setTotal(response.data);
            if (activeTab === 'transactions') {
                setTransactionsData(response.data.transactions);
            } else {
                setInternalData(response.data.transactions);
            }
            setTotalPage(Math.ceil(response.data.records_count / limit));
            setClaimedTokens(response.data.claimed_tokens);
        })
        .catch((error) => {
            console.error("Error fetching data:", error);
            setError(error);
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setPage(1);
        setSkip(0);
    };

    const renderTableHeaders = () => {
        if (activeTab === 'transactions') {
            return (
                <>
                    <th className='table_heading josefin w'>S No.</th>
                    <th className='table_heading josefin w'>AMOUNT</th>
                    <th className='table_heading josefin w'>From</th>
                    <th className='table_heading josefin w'>Type</th>
                    <th className='table_heading josefin w'>DATE</th>
                </>
            );
        } else if (activeTab === 'internal') {
            return (
                <>
                    <th className='table_heading josefin w'>S No.</th>
                    <th className='table_heading josefin w'>ID</th>
                    <th className='table_heading josefin w'>Amount</th>
                    <th className='table_heading josefin w'>Transaction Hash    </th>
                    {/* <th className='table_heading josefin w'>status</th> */}
                    <th className='table_heading josefin w'>Date</th>
                </>
            );
        }
    };

    const renderTransactionRows = () => {
        return transactionsData.length > 0 ? (
            transactionsData?.map((item, index) => {
                const serialNumber = (page - 1) * limit + index + 1;
                return (
                    <tr key={index} className='table_row'>
                        <td className='table_data inter w'>{serialNumber}</td>
                        <td className='table_data inter w'>{item.amount}</td>
                        <td className='table_data inter w'>{item.from}</td>
                        <td className='table_data inter w'>{item.type}</td>
                        <td className='table_data inter w'>{item.date}</td>
                    </tr>
                );
            })
        ) : (
            <tr>
                <td className="table_data inter w" colSpan="5">Data not found</td>
            </tr>
        );
    };

    const renderInternalRows = () => {
        return internalData.length > 0 ? (
            internalData?.map((item, index) => {
                const serialNumber = (page - 1) * limit + index + 1;
                return (
                    <tr key={index} className='table_row'>
                        <td className='table_data inter w'>{serialNumber}</td>
                        <td className='table_data inter w'>{item.user_id}</td>
                        <td className='table_data inter w'>{item.amount}</td>
                        <td className='table_data inter w'>{item.transfer_hash}</td>
                        {/* <td className='table_data inter w'>{item.transfer_status}</td> */}
                        <td className='table_data inter w'>{item.date}</td>
                    </tr>
                );
            })
        ) : (
            <tr>
                <td className="table_data inter w" colSpan="5">Data not found</td>
            </tr>
        );
    };

    const renderTableRows = () => {
        if (activeTab === 'transactions') {
            return renderTransactionRows();
        } else if (activeTab === 'internal') {
            return renderInternalRows();
        }
    };

    return (
        <>
            <section className='sec01_pool1 '>
                <div className='container'>
                    <h2 className='sec_heading_h2 w josefin text-center'>Tomax Income</h2>
                    <div className='sec2_box sec_tom'>
                        
                        <div className='d-flex table_pagi'>
                            
                            <div className='flex1'>
                            
                                    <h3 className='table_sub_title josefin w'>
                                        (Total: {total.total_income} Claimed: {claimedTokens})
                                    </h3>
                            
                                {/* <h3 className='table_sub_title josefin w'>(Total: {total.total_income} Claimed: {claimedTokens})</h3>    */}
                                <ul className="nav nav-tabs tomax_tab" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link tomax_btn tab_btn1 w josefin  ${activeTab === 'transactions' ? 'active' : ''}`}
                                            id="transactions-tab"
                                            onClick={() => handleTabChange('transactions')}
                                            type="button" role="tab"
                                            aria-controls="transactions"
                                            aria-selected={activeTab === 'transactions'}>
                                           Claim  Transactions
                                        </button>
                                    </li>
                                    <li className="nav-item api" role="presentation">
                                        <button className={`nav-link tomax_btn tab_btn1 w josefin  ${activeTab === 'internal' ? 'active' : ''}`}
                                            id="internal-tab"
                                            onClick={() => handleTabChange('internal')}
                                            type="button" role="tab"
                                            aria-controls="internal"
                                            aria-selected={activeTab === 'internal'}>
                                             Claim Token History 
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className='flex2'>
                                <Pagination
                                    style={{ cursor: "pointer" }}
                                    className="mx-auto"
                                    current={page}
                                    total={totalPage * limit}
                                    pageSize={limit}
                                    showLessItems
                                    showMoreItems
                                    onChange={handlePaginationChange}
                                    showSizeChanger={false}
                                    showQuickJumper={false}
                                />
                            </div>
                        </div>
                        <div className="tab-content">
                            <div className={`tab-pane fade ${activeTab === 'transactions' ? 'show active' : ''}`} id="transactions" role="tabpanel" aria-labelledby="transactions-tab">
                                <div className='over'>
                                    {isLoading ? (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
                                            <ColorRing colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']} height={65} width={65} />
                                        </div>
                                    ) : (
                                        <table className='MyTeamDirects_table'>
                                            <thead>
                                                <tr className='table_row'>
                                                    {renderTableHeaders()}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderTableRows()}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'internal' ? 'show active' : ''}`} id="internal" role="tabpanel" aria-labelledby="internal-tab">
                                <div className='over'>
                                {isLoading ? (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
                                            <ColorRing colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']} height={65} width={65} />
                                        </div>
                                    ) : (
                                        <table className='MyTeamDirects_table'>
                                            <thead>
                                                <tr className='table_row'>
                                                    {renderTableHeaders()}
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {renderTableRows()}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Tomaxthree;
