

import logo from './logo.svg';
import './App.css';
import './Css/style.css'
import Landing from './Pages/Landing'
import toast, { Toaster } from 'react-hot-toast';
import Dashboard from './Pages/Dashboard';
import Header from './Pages/Header';
import Footer from './Pages/Footer';
import EditProfile from './Pages/EditProfile';
import PoolChartC1 from './Pages/PoolChartC1';
import MyTeam from './Pages/MyTeam';
import DirectTeam from './Pages/DirectTeam';
import MyTeamDirects from './Pages/MyTeamDirects';
import Teamtree from './Pages/Teamtree';
import PoolChart from './Pages/PoolChart';
import LevelIncome from './Pages/LevelIncome';
import PoolIncome from './Pages/PoolIncome';
import IncomeLedger from './Pages/IncomeLedger';
import Withdrawhistory from './Pages/Withdrawhistory';
import TicketHistory from './Pages/TicketHistory';
import Ticket from './Pages/Ticket';
import ConnectWallet from './Pages/ConnectWallet';
import Login from './Pages/Login';
import WithdrawList from './Pages/WithdrawList'
import Tomax from './Pages/Tomax'
import IncomeLedger2 from './Pages/Income2leger'
import { BrowserRouter as Router, Switch, Route, Link, Outlet, Routes } from "react-router-dom";
import LevelDetail from './Pages/LevelDetail';
import DirectIncome from './Pages/DirectIncome';
import Pdf from './Pages/Pdf ';
import ClaimTomaxhistory from './Pages/Claimedhistory';
import Tomaxthree from './Pages/Tomaxthree';
import Threeincome from './Pages/Threelevelincome';
import Threedirectincome from './Pages/threedirectincome'
import Threepoolincome from './Pages/Threepoolincome'
const User = () => {
  

  return (
    <>
       <Header />
      <Outlet />
     <Footer />
    </>
  );
}

const AuthLogin = () => {
  return (
    <>
      <Outlet />
    </>
  );
}


function App() {
  return (
    <Router>
      <Routes>
        <Route element={<User />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path='/Header' element={<Header />} />
          <Route path='/EditProfile' element={<EditProfile />} />
          <Route path='/PoolChartC1/:pool_id' element={<PoolChartC1 />} />
          <Route path='/MyTeam' element={<MyTeam />} />
          <Route path='/WithdrawList' element={<WithdrawList />} />
          <Route path='/LevelDetail' element={<LevelDetail/>}/>
          <Route path="DirectTeam" element={<DirectTeam/>}/>
          <Route path='/MyTeamDirects' element={<MyTeamDirects />} />
          <Route path='/Teamtree' element={<Teamtree />} />
          <Route path='/PoolChart' element={<PoolChart />} />
          <Route path='/LevelIncome' element={<LevelIncome />} />
          <Route path='/PoolIncome' element={<PoolIncome />} />
          <Route path='/DirectIncome' element={<DirectIncome/>}/>
          <Route path='/IncomeLedger' element={<IncomeLedger />} />
          <Route path='/Tomax' element={<Tomax />} />
          <Route path='/Tomaxthree' element={<Tomaxthree />} />
          <Route path='/Pdf' element={<Pdf />} />
          <Route path='/Withdrawhistory' element={<Withdrawhistory />} />
          <Route path='/TicketHistory' element={<TicketHistory />} />
          <Route path='/Ticket' element={<Ticket />} />
          <Route path='/' element={<Landing />} />
          <Route path='/ConnectWallet' element={<ConnectWallet />} />
          <Route path='/ClaimTomaxhistory' element={<ClaimTomaxhistory />} />
          <Route path='/threeincome' element={<Threeincome />} />
          <Route path='/threedirectincome' element={<Threedirectincome />} />
          <Route path='/threepoolincome' element={<Threepoolincome />} />
        </Route>
        <Route path="/Login" element={<AuthLogin />}>
           <Route path="/Login" element={<Login />} />
         </Route>
      </Routes>
      <Toaster />
    </Router>
  );
}

export default App;
