import React,{useState,useContext,useEffect} from 'react'
import axios from 'axios';
import { UserIdContext } from '../context/walletContext';
import { NavLink } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import { base_url } from './Base_url'

const MyTeam = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const {userId}=useContext(UserIdContext)

    useEffect(() => {
        const fetchDataAsync = async () => {
            setIsLoading(true);
            try {
                await fetchData();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error("Error fetching data:", error);
                setError(error);
            }
        };

        fetchDataAsync();
    }, []);


    const fetchData = () => {
        setIsLoading(true);
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${base_url}/Api/team_count/${userId}`,
            headers: {
                'Content-Type': "application/x-www-form-urlencoded"
            },
            // data: data
        };

        return axios.request(config)
            .then((response) => {
                console.log(response);
                (JSON.stringify(response))
                console.log("Response:", response.data.users);
                setData(response.data.users);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setError(error);
            }) .finally(() => {
                setIsLoading(false);
            });
    }

  return (
    <>
    <section className='sec01_pool1'>
        <div className='container'>
        <h2 className='sec_heading_h2 w josefin text-center'>My Team</h2>
            <div className='sec2_box'>
                <div className='d-flex table_pagi'>
                            <div className='flex1'>
                                {/* <h3 className='table_sub_title josefin w' >( Total : 387.164 )</h3> */}
                            </div>
                            <div className='flex2'>
                                {/* <nav aria-label="Page navigation example">
                                <ul class="pagination pagi_nation">
                                    <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Previous">
                                        <span className='b' aria-hidden="true b">&laquo;</span>
                                    </a>
                                    </li>
                                    <li class="page-item pagi_nation_item"><a class="page-link b josefin" href="#">1</a></li>
                                    <li class="page-item pagi_nation_item"><a class="page-link b josefin" href="#">2</a></li>
                                    <li class="page-item pagi_nation_item"><a class="page-link b josefin" href="#">3</a></li>
                                    <li class="page-item pagi_nation_item">
                                    <a class="page-link" href="#" aria-label="Next">
                                        <span className='b' aria-hidden="true">&raquo;</span>
                                    </a>
                                    </li>
                                </ul>
                                </nav> */}
                            </div>
                </div>
                {isLoading ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
                                    <ColorRing colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']} height={65} width={65} />
                                </div>
                            ) : (
                <table className='pool1_table'>
                    <tr className='table_row'>
                        <th className='table_heading josefin w'> LEVEL</th>
                        <th className='table_heading josefin w'>TEAM</th>
                        <th className='table_heading josefin w'>VIEW</th>
                    </tr>
                    { data?.length > 0 ? (
                    data?.map((item, index) =>{
                        return (
                    <tr key={index}className='table_row'>
                        <td className='table_data inter w'>{item.level}</td>
                        <td className='table_data inter w'>{item.team}</td>
                        <td className='table_data inter w'><NavLink  to={`/LevelDetail?level=${item.level}`} className='table_link'>View</NavLink></td>
                    </tr>
                    )})):(
                        <tr>
                        <td className="table_data inter w">Data not found</td>
                      </tr>
                    )}

                    </table>
                            )}
            </div>
        </div>
    </section>
    </>
  )
}

export default MyTeam