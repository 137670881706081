
import React,{useState,useContext} from 'react'
import {Web3} from 'web3'
import logo from '../Images/logo.png'
import Toggel from '../Images/Toggle.png'
import { WalletContext ,UserIdContext} from '../context/walletContext'
import {Link, NavLink, useLocation,useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';

const Header = () => {
  const [web3, setWeb3] = useState(null)
  const { setWalletAddress } = useContext(WalletContext);
  const{setUserId}=useContext(UserIdContext)
  const location = useLocation();
  const navigate=useNavigate()

const isLandingPage = () => {
    return location.pathname === '/';
  };

  
  const isConnectedWallet = () => {
    return location.pathname === '/ConnectWallet';
  };


//   const ConnectWallet=async()=>{
//     console.log("for connect wallet");
//     if(window.ethereum){
//         const web3Instance=new Web3(window.ethereum)
//         setWeb3(web3Instance)
//         try {
//             await window.ethereum.request({ method: 'eth_requestAccounts' });
//             console.log("MetaMask connected successfully"); 
//                 const walletAddress=await web3.eth.getAccounts() 
//                 console.log(walletAddress);
//                 console.log("walletAddress.......",walletAddress[0]);
//                 setWalletAddress(walletAddress[0])   
//                 navigate("/Login");      
//         } catch (error) {
//             console.error("user denied account access");
//         }
//     }
// }

const ConnectWallet = async () => {
  console.log("for connect wallet");
  if (window.ethereum) {
    const web3Instance = new Web3(window.ethereum);
    setWeb3(web3Instance);
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log("MetaMask connected successfully");
      const accounts = await web3Instance.eth.getAccounts(); // Use the web3Instance here
      if (accounts.length > 0) {
        const walletAddress = accounts[0];
        console.log("walletAddress.......", walletAddress);
        setWalletAddress(walletAddress);
        navigate("/Login");
      } else {
        console.error("No accounts found");
        toast.error('No accounts found')
      }
    } catch (error) {
      console.error("user denied account access");
      toast.error('User denied account access')
    }
  } else {
    console.error("Please install MetaMask!");
    toast.error('Please install MetaMask!')
  }
};


const handleLogout = () => {
  setUserId(null);
  localStorage.removeItem('userId');
  localStorage.removeItem('walletAddress');
  localStorage.clear()

  navigate('/'); 
};

  return (
    <>
        <header className='header'>
            <div className='container head_con'>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid p-0">
                    <NavLink className="navbar-brand" to="/"> <img className='logo' src={logo} alt="Logo" /> </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"><img className='Toggel' src={Toggel} alt="Toggle" /></span>
                    </button>
                    <div className="collapse navbar-collapse nav_bar" id="navbarNav">
                    <ul className="navbar-nav nav-bar">
                    {isLandingPage() &&
                    <li className="nav-item">
                      <NavLink className='btn_m header_btn josefin w' to="/ConnectWallet"> Register   </NavLink>
                    </li>
                  }
                  {isConnectedWallet() &&
                    <li className="nav-item">
                      <button className='btn_m header_btn josefin w'  onClick={ConnectWallet}> Register</button>
                    </li>
                  }
                       {!isLandingPage() && !isConnectedWallet() &&
                            <>
                                <li className="nav-item dropdown">
                             <a className="nav-link dropdown-toggle active w josefin" href="#" id="navbarDropdownMenuLink" aria-current="page" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                 My Profile
                             </a>
                             <ul className="dropdown-menu dropdown_list" aria-labelledby="navbarDropdownMenuLink">
                                 <li><NavLink className="dropdown-item drop_i_bg inter w " to="/EditProfile">Edit Profile</NavLink></li>
                             </ul>
                         </li>
                         <li className="nav-item dropdown">
                             <a className="nav-link dropdown-toggle w josefin" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                             Pool Chart C List
                             </a>
                             <ul className="dropdown-menu dropdown_list" aria-labelledby="navbarDropdownMenuLink">
                                 <li><NavLink className="dropdown-item drop_i_bg inter w " to="/PoolChartC1/1">Pool Chart C 1</NavLink></li>
                                 <li><NavLink className="dropdown-item drop_i_bg inter w" to="/PoolChartC1/2">Pool Chart C 2</NavLink></li>
                                 <li><NavLink className="dropdown-item drop_i_bg inter w" to="/PoolChartC1/3">Pool Chart C 3</NavLink></li>
                                 <li><NavLink className="dropdown-item drop_i_bg inter w" to="/PoolChartC1/4">Pool Chart C 4</NavLink></li>
                                 <li><NavLink className="dropdown-item drop_i_bg inter w" to="/PoolChartC1/5">Pool Chart C 5</NavLink></li>
                                 <li><NavLink className="dropdown-item drop_i_bg inter w" to="/PoolChartC1/6">Pool Chart C 6</NavLink></li>
                             </ul>
                         </li>
                         <li className="nav-item dropdown">
                             <a className="nav-link dropdown-toggle w josefin" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                             My Account  
                             </a>
                             <ul className="dropdown-menu dropdown_list" aria-labelledby="navbarDropdownMenuLink">
                                 <li><NavLink className="dropdown-item drop_i_bg inter w " to="/MyTeam"> My Team</NavLink></li>
                                 <li><NavLink className="dropdown-item drop_i_bg inter w " to="/DirectTeam">Direct Team</NavLink></li>
                                 {/* <li><NavLink className="dropdown-item drop_i_bg inter w " to="">Level Team</NavLink></li> */}
                                 <li><NavLink className="dropdown-item drop_i_bg inter w " to="/Teamtree">My Team Tree</NavLink></li>
                                 <li><NavLink className="dropdown-item drop_i_bg inter w " to="/PoolChart"> Pool Chart</NavLink></li>
                                 <li><NavLink className="dropdown-item drop_i_bg inter w " to="/DirectIncome"> Direct Income</NavLink></li>
                                 <li><NavLink className="dropdown-item drop_i_bg inter w " to="/LevelIncome"> Level Income</NavLink></li>
                                 <li><NavLink className="dropdown-item drop_i_bg inter w " to="/PoolIncome"> Pool Income</NavLink></li>
                                 <li><NavLink className="dropdown-item drop_i_bg inter w " to="/IncomeLedger">  Income Ledger</NavLink></li>
                                 {/* <li><NavLink className="dropdown-item drop_i_bg inter w " to="/IncomeLedger2">  Income 2Ledger</NavLink></li> */}
                                 <li><NavLink className="dropdown-item drop_i_bg inter w " to="/" onClick={handleLogout}> Logout</NavLink></li>
                             </ul>
                         </li>
                         
                         {/* <li className="nav-item">
                            <NavLink className="nav-link josefin w" to="/Withdrawhistory">Withdraw History</NavLink>
                         </li> */}
                         <li className="nav-item">
                            <NavLink className="nav-link josefin w" to="/WithdrawList">Withdraw History</NavLink>
                         </li>
                         {/* <li className="nav-item">
                             <NavLink className="nav-link josefin w" to="">Generate Ticket</NavLink>
                         </li> */}
                         <li className="nav-item">
                         <NavLink className="nav-link josefin w" to="/Pdf">PDF</NavLink>
                         </li>
                            </>
                        }
                    </ul>
                    </div>
                </div>
                </nav>
            </div>
        </header>
    </>
  )
}

export default Header


