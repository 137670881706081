import React from 'react'
import logo from '../Images/logo.png'

const Footer = () => {
  return (
    <>
    <footer>
        <div className='container'>
           <ul className='footer_list d-flex'>
             <li className='footer_list_item footer_list_item1 align-self'> <a href='https://bscscan.com/address/0x76c49a0d31729b7be3970a28c7b6ddec2717eca5' target="_blank" className='footer_link josefin w'>Smart Contract info</a></li>
             {/* <li className='footer_list_item footer_list_item2 align-self'> <a href='https://bscscan.com/address/0x3BDa22B34c7672A1B94F433537A4Ed1d71F2ae14#tokentxns' className='footer_link josefin w'>Auto Pool info</a></li> */}
             <li className='footer_list_item footer_list_item2 align-self'> <a  href='https://bscscan.com/address/0xfd7f632415d8d4c2d36ede43dc4822f00619d1e3#tokentxns'  target="_blank"className='footer_link josefin w'>Auto Pool info</a></li>
             <li className='footer_list_item footer_list_item3 align-self'> <a href='/'  className='footer_link josefin w'><img className='logo' src={logo} /></a></li>
             <li className='footer_list_item footer_list_item5 align-self'> <a href='https://bscscan.com/token/0x3bAeE676e830dc1125d5D9322DFf30aB6A5b64cD' target="_blank" className='footer_link josefin w'>Tomax Contract info</a></li>
             <li className='footer_list_item footer_list_item4 align-self'> <a href='https://bscscan.com/address/0x0AD8b5648A8B02E694e1a6Af4cd9f633c529e766#tokentxns'target="_blank" className='footer_link josefin w'>Auto Pool Plan C</a></li>
           
           </ul>

            <h6 className='footer_text inter w'>© 2024 alphawin | All Rights Reserved.</h6>
        </div>
    </footer>
    </>
  )
}

export default Footer